<template>
    <div class="container">
        <div class="row">
            <div class="map-tools">
                <div class="address-form">
                    <input type="text" class="address-input" v-model="addressInput" @keyup.enter="lookupAddress" placeholder="Address"/>
                    <input type="text" class="country-input" v-model="addressCountry" @keyup.enter="lookupAddress" placeholder="Country"/>
                    <button type="button" @click="lookupAddress">Find</button>
                </div>
                <div v-if="addressMessage" :class="addressClass" @click="addressMessage = ''">
                    {{ addressMessage }}
                </div>
                <div class="address-results" v-if="addressResults.length > 1">
                    <div v-for="(result, index) in addressResults" :key="index" @click="setSelectedAddress(result)">{{ result.formatted_address }}</div>
                </div>
            </div>
            <div class="map-tools">
                <button type="button" title="Select a point on the map." @click="getTerrainProfilesButtonClicked">Get Terrain Profiles</button>
                <button type="button" @click="showSettings = !showSettings">{{ showSettings ? 'Hide' : 'Show' }} settings</button>
            </div>
            <div class="map-tools" v-if="showSettings">
                <label>Profile distance: <input type="text" class="topo_setting" v-model="settings.terrain_profile_distance"/> meters</label><br/>
                <label>Sample every <input type="text" class="topo_setting" v-model="settings.sample_spacing"/> meters</label><br/>
                <label>Structure height: <input type="text" class="topo_setting" v-model="settings.structure_height"/> meters</label><br/>
                <label>Flat gradient tolerance +/-: <input type="text" class="topo_setting" v-model="settings.flat_gradient_tolerance"/></label><br/>
                <label>Heading Step: <input type="text" class="topo_setting" v-model="settings.request_heading_step"/></label> &deg;<br/>
            </div>
        </div>
        <div class="row">
            <div ref="mapCanvas" class="map-canvas"></div>
            <div ref="tableWrapper" class="table-wrapper" style="display: none;">
                <div ref="elevationTable"></div>
            </div>
            <div ref="chartWrapper" class="elevation-chart-wrapper" style="display: none;">
                <div>
                    <button type="button" @click="minHeightButtonClicked" title="Press this button, then select a point on the graph.">Select Min Height</button>
                    <button type="button" @click="maxHeightButtonClicked" title="Press this button, then select a point on the graph.">Select Max Height</button>
                </div>
                <div class="wind-direction-arrow" title="Wind direction right to left.">Wind Direction: &#8678;</div>
                <div ref="chartContainer" class="elevation-chart"></div>
            </div>
            <div ref="extraCharts"></div>
            <div ref="exportTableWrapper" class="export-table-wrapper" style="display: none;">
                <div class="left-heading">Rotated Output</div>
                <div ref="exportTable" class="export-table"></div>
            </div>
        </div>
    </div>
</template>

<script>
import TopoProfilesRequest from '../topo/TopoProfilesRequest.js';
import TopoChart from '../topo/TopoChart.js';
import {ref, onMounted, nextTick} from 'vue';

class TopoMap {
    constructor(google, position, elements) {
        /** @prop google */
        /** @prop google.maps.Map */
        /** @prop google.maps.LatLng */
        /** @prop google.maps.MapTypeControlStyle.HORIZONTAL_BAR */
        /** @prop google.maps.ControlPosition.TOP_RIGHT */
        /** @prop google.maps.Geocoder */
        /** @prop google.maps.ElevationService */
        /** @prop google.maps.InfoWindow */
        /** @prop google.maps.Marker */
        /** @prop google.maps.Polyline */
        /** @prop google.maps.MapTypeId.TERRAIN */
        /** @prop google.maps.GeocoderStatus.OK */
        /** @prop google.visualization.Table */
        /** @prop this._geocoder.geocode */
        /** @prop this._infowindow.setContent */
        /** @prop this._map.setCenter */
        /** @prop this._map.panTo */
        /** @prop this._map.setZoom */
        /** @prop this._map.setOptions */
        /** @prop this._addressMarker.setTitle */
        /** @prop this._profileTable.draw */
        /** @prop this._profileTable.setSelection */
        this.google = google;
        this.settings = {
            terrain_profile_distance: 750,
            sample_spacing: 10,
            request_heading_step: 15,
            structure_height: 3, // z = reference height on the structure above the average local ground level
            flat_gradient_tolerance: 0.05, // +/-
            request_delay: 400, //ms
        };
        const [lat, lng] = position;
        position = new google.maps.LatLng(lat, lng);

        this._terrainProfileRequest = null;
        this._profileOrigin = null;
        this._selectedLocation = null;
        this._selectedProfile = null;
        this._selectingNewMin = false;
        this._selectingNewMax = false;

        this._profileOriginSymbol = {
            path: 'M -8,-8 8,8 M 8,-8 -8,8',
            strokeColor: '#00F',
            strokeWeight: 2,
        };

        this._elevator = new google.maps.ElevationService();
        this._profileTable = null;
        this._geocoder = new google.maps.Geocoder();
        this._infowindow = new google.maps.InfoWindow();
        this._addressMarker = new google.maps.Marker({
            map: null,
            position: position,
            draggable: true,
        });
        this._mapOverlays = [];
        this.google.load('visualization', '1', {packages: ['table', 'corechart']});

        this.setSelectedLocation(position);
        const options = {
            zoom: 5,
            center: position,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT,
            },
            panControl: false,
            zoomControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
        };
        this.elements = {
            chartContainer: elements.chartContainer.value,
            chartWrapper: elements.chartWrapper.value,
            mapCanvas: elements.mapCanvas.value,
            elevationTable: elements.elevationTable.value,
            tableWrapper: elements.tableWrapper.value,
            extraCharts: elements.extraCharts.value,
            exportTableWrapper: elements.exportTableWrapper.value,
            exportTable: elements.exportTable.value,
        };
        this._map = new google.maps.Map(this.elements.mapCanvas, options);

        google.maps.event.addListener(this._map, 'rightclick', event => this.showInfoWindow(event, () => this.getTerrainProfilesAt(event.latLng)));
        google.maps.event.addListener(this._map, 'click', event => this.setSelectedLocation(event.latLng));

        new google.maps.Polyline({map: this._map});

        google.maps.event.addListener(this._addressMarker, 'dragend', event => {
            this.findNearestAddress(event.latLng);
            this.setSelectedLocation(event.latLng);
        });

        this.hideElevationTable(true);

        this._onSetAddress = null;
    }

    getTerrainProfilesForSelectedLocation() {
        this.getTerrainProfilesAt(this._selectedLocation);
    }

    showInfoWindow(event, onClick) {
        const button = document.createElement('button');
        button.textContent = 'Get terrain profiles';
        button.addEventListener('click', onClick);
        this._infowindow.setContent(button);
        this._infowindow.setPosition(event.latLng);
        this._infowindow.open(this._map);
        this.setSelectedLocation(event.latLng);
    }

    setSelectedLocation(latLng) {
        this._selectedLocation = latLng;
    }

    lookUpAddress(address) {
        return new Promise((win, fail) => {
            this._geocoder.geocode({address}, (results, status) => {
                if (status === this.google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                    return fail('Query limit exceeded');
                }
                if (status === this.google.maps.GeocoderStatus.REQUEST_DENIED) {
                    return fail('Request denied');
                }
                if (status === this.google.maps.GeocoderStatus.INVALID_REQUEST) {
                    return fail('Invalid request');
                }
                if (status === this.google.maps.GeocoderStatus.UNKNOWN_ERROR || status === this.google.maps.GeocoderStatus.ERROR) {
                    return fail('Unknown error, try again.');
                }
                if (status === this.google.maps.GeocoderStatus.ZERO_RESULTS) {
                    return win({results: [], status});
                }
                if (status === this.google.maps.GeocoderStatus.OK) {
                    const [result] = results;
                    this.setAddress(result, true);
                }
                win(results);
            });
        });
    }

    minMaxSelected(index, terrainProfile) {
        let updated = true;
        if (!this._selectingNewMin && !this._selectingNewMax) {
            terrainProfile.processFrom(index);
        } else if (this._selectingNewMin) {
            terrainProfile.setMin(index);
        } else if (this._selectingNewMax) {
            terrainProfile.setMax(index);
        } else {
            updated = false;
        }
        this.minMaxSelectEnd();
        return updated;
    }

    minMaxSelectStart(isMin) {
        this.minMaxSelectEnd();
        if (isMin) {
            this._selectingNewMin = true;
        } else {
            this._selectingNewMax = true;
        }
    }

    minMaxSelectEnd() {
        this._selectingNewMin = false;
        this._selectingNewMax = false;
    }

    drawLine(start, finish, colour, title) {
        const line = new this.google.maps.Polyline({
            title: title,
            strokeColor: colour,
            strokeOpacity: 0.5,
            strokeWeight: 3,
            path: [start, finish],
            map: this._map,
        });
        this._mapOverlays.push(line);
        return line;
    }

    createMarker(latLng, title) {
        const marker = new this.google.maps.Marker({
            position: latLng,
            title: title,
            map: this._map,
        });
        this._mapOverlays.push(marker);
        return marker;
    }

    getTerrainProfilesAt(origin) {
        if (this._terrainProfileRequest) {
            this._terrainProfileRequest.cancel();
        }

        this._selectedLocation = origin;
        this._profileOrigin = origin;
        this._map.panTo(origin);
        this._infowindow.close();
        this.clearOverlays();
        this.findNearestAddress(origin);
        const siteMarker = new this.google.maps.Marker({
            position: origin,
            title: 'Site',
            icon: this._profileOriginSymbol,
            map: this._map,
        });
        this._mapOverlays.push(siteMarker);
        this._addressMarker.siteMarker = siteMarker;

        const profileRequest = new TopoProfilesRequest(this._elevator, origin, this.settings, this.google, this, this.elements.chartContainer);
        profileRequest.onNewProfile(profile => {
            profile.onMarkerClick(() => this.drawChart(profile, this.elements.chartContainer, true));
            if (profileRequest.terrainProfiles.length % 2) {
                this._selectedProfile = profile;
                this.hideElevationTable(false);
                nextTick(() => this.drawChart(profile, this.elements.chartContainer, true));
            }
        });
        profileRequest.onFinish(() => {
            this._map.panTo(origin);
            this._map.setZoom(15);
            this._profileTable = new this.google.visualization.Table(this.elements.elevationTable);
            this.drawProfileTable();
            this.google.visualization.events.addListener(this._profileTable, 'select', () => {
                setTimeout(() => this.tableRowSelected(), 10);
            });
            this.hideElevationTable(false);
            if (!profileRequest.successful) {
                alert('There was an error while requesting terrain profiles, data for some directions may be missing.');
            }
        });
        profileRequest.start();

        this._terrainProfileRequest = profileRequest;
    }

    findNearestAddress(latLng) {
        this._geocoder.geocode({'latLng': latLng}, (results, status) => {
            if (status === this.google.maps.GeocoderStatus.OK) {
                this.setAddress(results[0]);
            }
        });
    }

    setAddress(addr, updateLocation = false) {
        if (updateLocation) {
            this.setSelectedLocation(addr.geometry.location);
        }
        /** @prop addr.formatted_address */
        this._addressMarker.address = addr;
        this._addressMarker.setTitle(addr.formatted_address);
        this._map.setCenter(addr.geometry.location);
        this._addressMarker.setMap(this._map);
        this._addressMarker.setPosition(addr.geometry.location);

        this._addressMarker.distToSite = this._profileOrigin ? this.google.maps.geometry.spherical.computeDistanceBetween(addr.geometry.location, this._profileOrigin) : 0;
        if (!this._profileOrigin || this._addressMarker.distToSite > 30) {
            this._addressMarker.awayFromSite = this._addressMarker.distToSite.toFixed(2) + 'm away from site.';
            this._addressMarker.setTitle(`${addr.formatted_address} (${this._addressMarker.awayFromSite})`);
            this._addressMarker.awayFromAddress = `${this._addressMarker.distToSite.toFixed(2)}m away from ${addr.formatted_address}`;
        } else {
            this._addressMarker.setMap(null);
            this._addressMarker.awayFromSite = this._addressMarker.awayFromAddress = addr.formatted_address;
        }
        if (this._addressMarker.siteMarker) {
            this._addressMarker.siteMarker.setTitle(this._addressMarker.awayFromAddress);
        }

        if (this._onSetAddress) {
            this._onSetAddress(addr);
        }
    }

    clearOverlays() {
        for (const overlay of this._mapOverlays) {
            overlay.setMap(null);
        }
        this._mapOverlays = [];
        this.hideElevationTable(true);
    }

    hideElevationTable(hideTable) {
        if (hideTable) {
            this.elements.chartWrapper.style.display = 'none';
            this.elements.tableWrapper.style.display = 'none';
            this.elements.tableWrapper.style.height = '';
            this.elements.mapCanvas.classList.remove('map-margins');
            this._map.setOptions({scrollwheel: true, zoomControl: false});
        } else {
            this.elements.chartWrapper.style.display = '';
            this.elements.mapCanvas.classList.add('map-margins');
            this._map.setOptions({scrollwheel: false, zoomControl: true});
        }
        this.google.maps.event.trigger(this._map, 'resize');
    }

    getProfilesFromTableSelection() {
        const profiles = [];
        const selection = this._profileTable.getSelection();
        /** @prop item.row */
        for (const item of selection) {
            profiles.push(this._terrainProfileRequest.terrainProfiles[item.row]);
        }
        return profiles;
    }

    tableRowSelected() {
        const profiles = this.getProfilesFromTableSelection();

        [this._selectedProfile] = profiles.length === 1 ? profiles : [this._selectedProfile];
        if (profiles.length > 1) {
            this.elements.chartWrapper.style.display = 'none';
            TopoChart.drawCharts(profiles, this.elements.extraCharts, this.google);
        } else {
            clearChildren(this.elements.extraCharts);
            this.elements.chartWrapper.style.display = '';
            this.drawChart(this._selectedProfile, this.elements.chartContainer, true);
        }

        this.displayRotatedExportData(profiles);
    }

    displayRotatedExportData(profiles) {
        if (profiles.length < 1) {
            this.elements.exportTableWrapper.style.display = 'none';
            return;
        }
        const exportData = {
            direction: ['Direction'],
            heading: ['Heading (&deg;)'],
            h: ['h (m)'],
            x: ['x (m)'],
            Lu: ['l<sub>u</sub> (m)'],
            Mh: ['M<sub>h</sub>'],
        };

        for (const profile of profiles) {
            exportData.direction.push(profile._direction.fname);
            exportData.heading.push(profile._heading.toFixed(3));
            exportData.h.push(profile._h.toFixed(3));
            exportData.x.push(profile._x.toFixed(3));
            exportData.Lu.push(profile._lu.toFixed(3));
            exportData.Mh.push(profile._mh.toFixed(3));
        }

        let html = '<table>';
        for (const x in exportData) {
            if (!exportData.hasOwnProperty(x)) continue;
            html += '<tr>';
            for (const y of exportData[x]) {
                html += '<td>' + y + '</td>';
            }
            html += '</tr>';
        }
        html += '</table>';

        this.elements.exportTable.innerHTML = html;
        this.elements.exportTableWrapper.style.display = '';
    }

    drawProfileTable() {
        this.elements.tableWrapper.style.display = '';

        const selection = this._profileTable.getSelection();
        const data = new this.google.visualization.DataTable();
        data.addColumn('number', 'Direction');
        data.addColumn('number', 'Heading (&deg;)');
        data.addColumn('number', 'h (m)');
        data.addColumn('number', 'x (m)');
        data.addColumn('number', 'l<sub>u</sub> (m)');
        data.addColumn('number', 'M<sub>h</sub>');
        data.addColumn('number', 'Slope');
        data.addColumn('number', 'Max-Height');
        data.addColumn('number', 'Min-Height');
        data.addColumn('number', 'Half-Height');
        data.addColumn('number', 'Worst Case');

        for (const profile of this._terrainProfileRequest.terrainProfiles) {
            data.addRow(profile.getTableData());
        }
        this._profileTable.draw(data, {showRowNumber: false, allowHtml: true});
        this._profileTable.setSelection(selection);
        nextTick(() => {
            // workaround to stop the page jumping when the table is redrawn
            const height = this.elements.tableWrapper.getBoundingClientRect().height;
            this.elements.tableWrapper.style.height = `${height}px`;
        });
    }

    drawChart(profile, chartContainer, addListeners = false) {
        const topoChart = new TopoChart(profile, this.google, chartContainer);
        topoChart.draw();
        profile.showMarkers();
        if (addListeners) {
            topoChart.onPointSelected(row => {
                if (!this.minMaxSelected(row, profile)) {
                    return;
                }

                topoChart.draw();
                this.drawProfileTable();
                this.displayRotatedExportData(this.getProfilesFromTableSelection());
                // this._map.panTo(this._profile._maxElevation.location);
            });
        }
    }
}

function scriptAlreadyLoaded(src) {
    const scripts = document.head.querySelectorAll('script');
    for (const script of scripts) {
        if (script.src.includes(src)) {
            return true;
        }
    }
    return false;
}

function loadScript(src) {
    if (scriptAlreadyLoaded(src)) {
        return false;
    }
    return new Promise(win => {
        const head = document.head;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.onload = () => win(true);
        head.appendChild(script);
    });
}

function clearChildren(element) {
    while (element.lastChild) {
        element.lastChild.remove();
    }
}

export default {
    name: 'Topo',
    setup() {
        let topoMap = null;
        const addressInput = ref('');
        const addressCountry = ref('New Zealand');
        const addressMessage = ref('');
        const addressResults = ref([]);
        const addressClass = ref('alert alert-info');
        const showSettings = ref(false);
        const elements = {
            chartContainer: ref(null),
            chartWrapper: ref(null),
            mapCanvas: ref(null),
            elevationTable: ref(null),
            tableWrapper: ref(null),
            extraCharts: ref(null),
            exportTableWrapper: ref(null),
            exportTable: ref(null),
        };
        const settings = ref({});
        onMounted(async () => {
            const createMap = () => {
                topoMap = new TopoMap(window.google, [-41.286514, 174.775915], elements);
                settings.value = topoMap.settings;
                topoMap._onSetAddress = address => {
                    const country = address.address_components.find(c => c.types.includes('country'));
                    if (country && country.long_name) {
                        addressInput.value = address.formatted_address.replace(`, ${country.long_name}`, '');
                        addressCountry.value = country.long_name;
                    } else {
                        addressInput.value = address.formatted_address;
                    }
                };
            };
            await Promise.all([
                loadScript('//www.google.com/jsapi'),
                loadScript(`//maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`),
            ]);
            if (!window.google) {
                return setTimeout(createMap, 20);
            }
            createMap();
        });

        const minHeightButtonClicked = () => {
            if (topoMap._selectingNewMin) {
                topoMap.minMaxSelectEnd();
            } else {
                topoMap.minMaxSelectStart(true);
            }
        };
        const maxHeightButtonClicked = () => {
            if (topoMap._selectingNewMin) {
                topoMap.minMaxSelectEnd();
            } else {
                topoMap.minMaxSelectStart(false);
            }
        };

        const getTerrainProfilesButtonClicked = () => topoMap.getTerrainProfilesForSelectedLocation();
        const lookupAddress = async () => {
            const address = addressInput.value + ' ' + addressCountry.value;
            addressMessage.value = '';
            addressResults.value = [];
            try {
                const results = await topoMap.lookUpAddress(address);
                if (!results.length) {
                    addressMessage.value = 'No results found for: ' + address;
                    addressClass.value = 'alert alert-warning';
                    return;
                }
                if (results.length === 1) {
                    return;
                }
                addressMessage.value = `${results.length} results found`;
                addressResults.value = results;
                addressClass.value = 'alert alert-info';
            } catch (err) {
                addressMessage.value = err;
                addressClass.value = 'alert alert-danger';
            }
        };
        const setSelectedAddress = address => {
            topoMap.setAddress(address, true);
            addressResults.value = [];
            addressMessage.value = '';
        };

        return {
            lookupAddress, addressInput, addressCountry, addressMessage, addressClass, addressResults, setSelectedAddress,
            getTerrainProfilesButtonClicked, showSettings,
            ...elements,
            settings,
            minHeightButtonClicked,
            maxHeightButtonClicked,
        };
    },
};
</script>

<style lang="scss">
.alert {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    border: solid 1px;
    margin: 1.5rem 0;
    padding: 0.75rem
}

.alert-success {
    background: #c8e5bc;
    border-color: #b2dba1;
}

.alert-info {
    background: #b9def0;
    border-color: #9acfea;
}

.alert-warning {
    background: #f8efc0;
    border-color: #f5e79e;
}

.alert-danger {
    background: #e7c3c3;
    border-color: #dca7a7;
}

.map-tools {
    margin: 1.5rem 0;
}

.map-canvas {
    min-height: 500px;
}

.address-form {
    display: flex;

    & > * {
        flex: 0 1 200px;
        min-width: 100px;
        padding: 0.25rem 1rem;
    }
}

.address-input {
    flex: 1;
    min-width: 200px;
    width: 33%;
}

.address-results > div {
    padding: 0.25rem 1rem;

    &:nth-child(even) {
        background: #efefef;
    }

    &:hover {
        background: #efffef;
        cursor: pointer;
    }
}

.map-margins {
    border: solid #ccc 1px;
    margin-bottom: 1.5rem;
}

.elevation-chart-wrapper {
    margin-bottom: 1.5rem;
}

.elevation-chart {
    min-height: 350px;
    border: solid #ccc 1px;
    margin-bottom: 1.5rem;
}

.table-wrapper {
    text-align: center;
    margin-bottom: 1.5rem;
}

.export-table td {
    padding-left: 15px;
    text-align: left;
}

.export-table-wrapper {
    background-color: #FFF;
    padding: 20px;
    border: solid #ccc 1px;
    overflow-x: auto;
    margin-bottom: 1.5rem;
    min-height: 250px;
}

.wind-direction-arrow {
    text-align: center;
    position: relative;
    z-index: 10;
}

.wind-direction-arrow img {
    vertical-align: middle;
}

.left-heading {
    font-weight: bold;
    text-align: left;
}

.google-visualization-table, .google-visualization-table-table {
    width: 100%;
}
</style>