import TerrainProfile from './TerrainProfile.js';

export default class TopoProfilesRequest {
    constructor(elevator, latLng, settings, google, topoMap) {
        /** @param google.maps.ElevationStatus.OK */
        /** @param google.maps.ElevationStatus.INVALID_REQUEST */
        /** @param google.maps.ElevationStatus.OVER_QUERY_LIMIT */
        /** @param google.maps.ElevationStatus.REQUEST_DENIED */
        /** @param google.maps.ElevationStatus.UNKNOWN_ERROR */
        /** @param elevator.getElevationAlongPath */
        this.settings = settings || {};
        this.google = google;
        this.topoMap = topoMap;
        this.latLng = latLng;
        this.elevator = elevator;
        this.requestDelay = this.settings.request_delay ? this.settings.request_delay : 333;

        this.profileDisance = this.settings.terrain_profile_distance;
        this.samplesPerRequest = Math.trunc(2 * this.profileDisance / this.settings.sample_spacing) + 1;

        const step = Math.abs(this.settings.request_heading_step) >= 1 ? Math.abs(this.settings.request_heading_step) : 15;
        this.headings = this.getHeadings(step);

        this.successful = false;
        this.cancelled = false;
        this.timer = null;
    }

    getHeadings(step) {
        const headings = [];
        let heading = 0;
        while (heading < 180) {
            headings.push(heading);
            heading += step;
        }
        return headings;
    }

    cancel() {
        this.cancelled = true;
        this.stop();
    }

    stop() {
        clearTimeout(this.timer);
    }

    start() {
        console.log('starting request');
        this.terrainProfiles = [];
        this.worstCases = [];
        this.totalResponses = 0;
        this.sendNextRequest();
    }

    requestElevationSamples(index) {
        const heading = this.headings[index];
        console.log('requesting profile for heading: ' + heading);
        const eastPoint = this.google.maps.geometry.spherical.computeOffset(this.latLng, this.profileDisance, heading);
        const westPoint = this.google.maps.geometry.spherical.computeOffset(this.latLng, this.profileDisance, (heading - 180));
        this.elevator.getElevationAlongPath({
            'path': [westPoint, this.latLng, eastPoint],
            'samples': this.samplesPerRequest,
        }, (results, status) => this.handleElevationResponse(index, results, status));
    }

    handleElevationResponse(index, results, status) {
        if (this.cancelled) {
            console.log('got response for cancelled request');
            return;
        }
        const heading = this.headings[index];

        this.totalResponses++;
        if (status == this.google.maps.ElevationStatus.OK) {
            this.handleResults(results);
        } else if (status == this.google.maps.ElevationStatus.INVALID_REQUEST) {
            console.log('Malformed elevation requests for heading: ' + heading);
        } else if (status == this.google.maps.ElevationStatus.OVER_QUERY_LIMIT) {
            let msg = 'Query limit exceeded for heading: ' + heading;
            if (this.totalResponses < 30) {
                console.log(msg + ', retrying...');
                this.timer = setTimeout(() => this.requestElevationSamples(index), this.requestDelay * 10);
            } else {
                console.log(msg + ', reached max requests, cancelling...');
                this.cancel();
                this.finish(false);
            }
            return;
        } else if (status === this.google.maps.ElevationStatus.REQUEST_DENIED) {
            // indicating the service did not complete the request, likely because on an invalid parameter
            console.log(`Request denied for heading: ${heading}`);
        } else if (status === this.google.maps.ElevationStatus.UNKNOWN_ERROR) {
            console.log(`Unknown error for heading: ${heading}`);
        } else {
            console.log(`Unknown status (${status}) received for heading: ${heading}`);
        }

        this.sendNextRequest(index);
    }

    handleResults(results) {
        const westData = [], eastData = [];
        let e = results.length - 1;

        let originIndex = 0;
        for (let w = 0; 0 <= e; w++) {
            westData[w] = results[w];
            eastData[w] = results[e];
            // the number of samples requested is always an odd number
            // where w meets e, it should be the point of origin
            if (w === e) {
                originIndex = w;
            }
            e--;
        }

        this.addProfile(new TerrainProfile(westData, originIndex, this.settings, this.google, this.topoMap));
        this.addProfile(new TerrainProfile(eastData, originIndex, this.settings, this.google, this.topoMap));
    }

    addProfile(profile) {
        this.terrainProfiles.push(profile);
        this.updateWorstCases(profile);
        if (this.newProfileListener) {
            this.newProfileListener(profile);
        }
    }

    sendNextRequest(index = null) {
        index = index === null ? 0 : index + 1;
        if (index === this.headings.length) {
            return this.finish(true);
        }

        this.timer = setTimeout(() => this.requestElevationSamples(index), this.requestDelay);
    }

    getProfilesForDirection(direction) {
        const directionProfiles = [];
        for (const profile of this.terrainProfiles) {
            if (profile._direction.heading == direction.heading) {
                directionProfiles.push(profile);
            }
        }
        return directionProfiles;
    }

    updateWorstCases(profile) {
        const directionProfiles = this.getProfilesForDirection(profile._direction);
        if (directionProfiles.indexOf(profile) < 0) {
            directionProfiles.push(profile);
        }

        let worstCaseIndex = -1;
        for (let i = 0; i < directionProfiles.length; i++) {
            let index = this.worstCases.indexOf(directionProfiles[i]);
            if (index >= 0) {
                worstCaseIndex = index;
                i = directionProfiles.length;
            }
        }

        const worstCase = this.findWorstCase(directionProfiles);
        if (worstCaseIndex >= 0) {
            this.worstCases[worstCaseIndex] = worstCase;
        } else {
            this.worstCases.push(worstCase);
        }
    }

    findWorstCase(directionProfiles) {
        let [worstCase] = directionProfiles;
        for (const profile of directionProfiles) {
            profile.hideMarkers();
            if (profile.isWorseThan(worstCase)) {
                worstCase.setWorstCase(false);
                worstCase = profile;
            } else {
                profile.setWorstCase(false);
            }
        }
        worstCase.setWorstCase(true);
        worstCase.showMarkers();
        return worstCase;
    }

    // events
    finish(success) {
        this.successful = success;
        console.log((success ? 'successfully completed' : 'failed to complete') + ' request');
        if (this.finishListener) {
            this.finishListener();
        }
    }

    onFinish(listener) {
        if (listener && typeof listener == 'function') {
            this.finishListener = listener;
        }
    }

    onNewProfile(listener) {
        if (listener && typeof listener == 'function') {
            this.newProfileListener = listener;
        }
    }
}
