export default class TopoChart {
    constructor(profile, google, container) {
        /** @prop google.visualization.LineChart */
        /** @prop google.visualization.events.removeAllListeners */
        /** @prop google.maps.event.trigger */
        /** @prop chart.getContainer */
        this.google = google;
        this.profile = profile;
        // this._onPointSelected = null;
        this.chart = new this.google.visualization.LineChart(container);
        this.google.maps.event.trigger(this.chart, 'resize');
        // this.container = container;
    }

    draw() {
        /** @prop chartData.getColumnLabel */
        const chartData = this.profile.getChartData();
        this.chart.draw(chartData, {
            title: this.profile._direction.fname + ' ' + this.profile._heading.toFixed(2) + '°',
            legend: 'none',
            animation: {
                duration: 100,
                easing: 'out',
            },
            titleY: chartData.getColumnLabel(1),
            titleX: chartData.getColumnLabel(0),
        });
        // if (this._onPointSelected) {
        //     this.onPointSelected(this._onPointSelected, chart);
        // }
        // this.chart = chart;
    }


    onPointSelected(cb) {
        this.google.visualization.events.removeAllListeners(this.chart, 'select');
        this.google.visualization.events.addListener(this.chart, 'select', () => {
            const selection = this.chart.getSelection();
            if (selection.length > 0) {
                cb(selection[0].row);
            }
        });
    }

    // draws/redraws charts for these profiles, removes charts for profiles not in this list
    static drawCharts(profiles, parentElement, google) {
        if (!profiles || profiles.length < 1) return;
        clearChildren(parentElement);
        for (const profile of profiles) {
            const div = document.createElement('div');
            const topoChart = new TopoChart(profile, google, div);
            div.classList.add('elevation-chart');
            parentElement.appendChild(div);
            topoChart.draw();
        }
    }
}

function clearChildren(element) {
    while (element.lastChild) {
        element.lastChild.remove();
    }
}