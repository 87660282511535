const _directions = [
    {name: 'N', fname: 'North', heading: 0},
    {name: 'NE', fname: 'Northeast', heading: 45},
    {name: 'E', fname: 'East', heading: 90},
    {name: 'SE', fname: 'Southeast', heading: 135},
    {name: 'S', fname: 'South', heading: 180},
    {name: 'SW', fname: 'Southwest', heading: -135},
    {name: 'W', fname: 'West', heading: -90},
    {name: 'NW', fname: 'Northwest', heading: -45},
];

export default class TerrainProfile {
    constructor(elevationData, originIndex, settings, google, topoMap) {
        this.settings = settings;
        this.topoMap = topoMap;
        /** @param google.maps.geometry.spherical.computeDistanceBetween */
        /** @param google.maps.geometry.spherical.computeHeading */
        /** @param google.maps.geometry.spherical.computeOffset */
        /** @param google.visualization.DataTable */
        /** @param google.maps.event.addListener */
        this.google = google;
        this._elevations = elevationData;
        this._originIndex = originIndex;
        this._siteElevation = this._elevations[this._originIndex];
        this._origin = this._siteElevation.location;
        this._destination = this._elevations[this._elevations.length - 1].location;
        this._distance = this.google.maps.geometry.spherical.computeDistanceBetween(this._origin, this._destination);
        this._heading = this.google.maps.geometry.spherical.computeHeading(this._origin, this._destination);
        this._direction = this.constructor.getGeneralDirectionForHeading(this._heading);

        this._maxElevation = false;
        this._minElevation = false;
        this._halfElevation = false;

        this._markers = [];

        this._isSiteWithinZone = false;
        this._isWorstCase = false;
        this._maxElevationIndex = 0;
        this._minElevationIndex = this._elevations.length - 2;
        this._halfElevationIndex = (this._minElevationIndex + this._maxElevationIndex) / 2;
        this.processAll();
    }

    static getGeneralDirectionForHeading(heading) {
        const range = 22.5;
        const limit = 180;
        for (let i = 0; i < _directions.length; i++) {
            if (_directions[i].heading == limit && (limit - range <= heading || range - limit > heading)) {
                return _directions[i];
            }
            if ((_directions[i].heading - range) <= heading && heading < (_directions[i].heading + range)) {
                return _directions[i];
            }
        }
        return _directions[0];
    }

    processAll() {
        let indexes = this.findMinMax(this._elevations.length - 2);
        while (indexes.maxIndex >= 1) {
            this.processData(indexes);
            indexes = this.findMinMax(indexes.maxIndex - 1);
        }
    }

    processFrom(index) {
        this.processData(this.findMinMax(index), true);
    }

    setMin(index) {
        index = Math.abs(Math.trunc(index));
        if (index >= this._elevations.length) return;
        this._minElevationIndex = index;
        this._minElevation = this._elevations[index];
        this.updateHalfHeight();
        this.processData(this.getIndexes(), true);
    }

    setMax(index) {
        index = Math.abs(Math.trunc(index));
        if (index >= this._elevations.length) return;

        this._maxElevationIndex = index;
        this._maxElevation = this._elevations[index];
        this.updateHalfHeight();
        this.processData(this.getIndexes(), true);
    }

    updateHalfHeight() {
        this._halfElevationIndex = this.findHalfHeightIndexBetween(this._minElevationIndex, this._maxElevationIndex);
        this._halfElevation = this._elevations[this._halfElevationIndex];
    }

    getIndexes() {
        return {minIndex: this._minElevationIndex, maxIndex: this._maxElevationIndex, halfIndex: this._halfElevationIndex};
    }

    findMinMax(fromIndex) {
        let maxElevation = this._elevations[fromIndex];
        let minElevation = this._elevations[fromIndex];
        let maxElevationIndex = fromIndex;
        let minElevationIndex = fromIndex;
        // let minFound = 0;
        // let maxFound = 0;
        let searchingFor, min, down, max, up;

        searchingFor = min = down = -this.settings.flat_gradient_tolerance;
        max = up = this.settings.flat_gradient_tolerance;

        /** @property this._elevations.elevation */
        for (let i = fromIndex; i > 1; i--) {
            if (i + 1 >= this._elevations.length) {
                continue;
            }
            const curEle = this._elevations[i];
            const nextEle = this._elevations[i + 1]; // next elevation is upwind of the current elevation

            // loop is running backwards, a negative gradient is sloping upwards.
            const slope = (curEle.elevation - nextEle.elevation) / this.google.maps.geometry.spherical.computeDistanceBetween(nextEle.location, curEle.location);

            if (searchingFor == min) {
                if (curEle.elevation <= minElevation.elevation) {
                    minElevationIndex = i;
                    maxElevationIndex = i;
                    minElevation = this._elevations[i];
                    maxElevation = this._elevations[i];
                    // minFound = 0;
                } else if (slope >= up) {
                    searchingFor = max;
                }
            }

            if (searchingFor == max) {
                if (curEle.elevation > maxElevation.elevation) {
                    maxElevationIndex = i;
                    maxElevation = this._elevations[i];
                    // maxFound = 0;
                } else if (slope <= down) {
                    searchingFor = 0;
                }
            }
        }

        return {minIndex: minElevationIndex, maxIndex: maxElevationIndex, halfIndex: this.findHalfHeightIndexBetween(minElevationIndex, maxElevationIndex)};
    }

    findHalfHeightIndexBetween(minIndex, maxIndex) {
        let maxElevation = this._elevations[maxIndex];
        let minElevation = this._elevations[minIndex];

        let halfElevation = this._elevations[maxIndex];
        let halfElevationIndex = maxIndex;
        let offset = minIndex < maxIndex ? minIndex : maxIndex;
        let limit = minIndex > maxIndex ? minIndex : maxIndex;

        let halfHeight = (maxElevation.elevation + minElevation.elevation) / 2;
        let smallestDifferenceFromMeanHeight = Math.abs(halfElevation.elevation - halfHeight);
        for (let i = offset; i < limit; i++) {
            if (Math.abs(this._elevations[i].elevation - halfHeight) < smallestDifferenceFromMeanHeight) {
                halfElevation = this._elevations[i];
                halfElevationIndex = i;
                smallestDifferenceFromMeanHeight = Math.abs(halfElevation.elevation - halfHeight);
            }
        }

        return halfElevationIndex;
    }

    processData(indexes, overwriteCurrentData) {
        let maxElevation = this._elevations[indexes.maxIndex];
        let minElevation = this._elevations[indexes.minIndex];
        let halfElevation = this._elevations[indexes.halfIndex];

        // h: height range, difference between min and max elevation
        // x: peak to house
        // lu: peak to half height
        // lTwo: distance from the peak where it is especially windy?

        let h = maxElevation.elevation - minElevation.elevation;
        let x = this.google.maps.geometry.spherical.computeDistanceBetween(this._origin, maxElevation.location);
        if (this._maxElevationIndex > this._originIndex) {
            x = -x;
        }
        let lu = this.google.maps.geometry.spherical.computeDistanceBetween(halfElevation.location, maxElevation.location);
        let slope = lu > 0 ? h / (2 * lu) : 0;
        let lOne = Math.max(0.36 * lu, 0.4 * h);
        let lTwo = 4 * lOne;

        let mh;
        if (slope < 0.05) {
            mh = 1.0;
        } else if (0.05 <= slope && slope < 0.45) {
            let overallSlope = Math.abs(maxElevation.elevation - this._siteElevation.elevation) / Math.abs(x);
            if (Math.abs(x) > lTwo && x < 0 && overallSlope < 0.05) {
                let minHeightIndex = this.findMinElevationIndexBetween(this._originIndex, indexes.maxIndex);
                let maxHeightIndex = this.findMaxElevationIndexBetween(this._originIndex, indexes.maxIndex);
                let minHeight = this._elevations[minHeightIndex].elevation;
                let maxHeight = this._elevations[maxHeightIndex].elevation;
                let roughSlope = (maxHeight - minHeight) / Math.abs(x);
                if (roughSlope < 0.08) { // escarpment
                    lTwo = 10 * lOne;
                }
            }
            mh = 1 + (h / (3.5 * (this.settings.structure_height + lOne)) * (1 - Math.abs(x) / lTwo));
            mh = mh < 1 ? 1 : mh;
        } else {
            mh = 1 + 0.71 * Math.abs(1 - Math.abs(x) / lTwo);
        }

        let siteIsWithinZone = x < lTwo;
        let headingToZoneEdge = this.google.maps.geometry.spherical.computeHeading(maxElevation.location, this._origin);
        let zoneEdge = this.google.maps.geometry.spherical.computeOffset(maxElevation.location, lTwo, headingToZoneEdge);
        let zoneEdgeIndex = -1;
        let zoneEdgeDist = this.google.maps.geometry.spherical.computeDistanceBetween(zoneEdge, this._elevations[0].location);
        for (let i = 0; i < this._elevations.length; i++) {
            let dist = this.google.maps.geometry.spherical.computeDistanceBetween(zoneEdge, this._elevations[i].location);
            if (dist < zoneEdgeDist) {
                zoneEdgeDist = dist;
                zoneEdgeIndex = i;
            }
        }

        if ((overwriteCurrentData === true || this._maxElevation === false)
            || (!this.ignoreSeparationZone(x, h, lu, lTwo, maxElevation.elevation) && siteIsWithinZone && !this._isSiteWithinZone)
            || (this.zoneIsInvalid() && this._mh <= mh)
        ) {
            this._maxElevation = maxElevation;
            this._minElevation = minElevation;
            this._halfElevation = halfElevation;
            this._maxElevationIndex = indexes.maxIndex;
            this._minElevationIndex = indexes.minIndex;
            this._halfElevationIndex = indexes.halfIndex;
            this._zoneEdge = zoneEdge;
            this._zoneEdgeIndex = zoneEdgeIndex;
            this._lTwo = lTwo;
            this._h = h;
            this._x = x;
            this._lu = lu;
            this._slope = slope;
            this._mh = mh;
            this._isSiteWithinZone = siteIsWithinZone;
            this.createMapMarkers();
            this.clearData();
        }
    }

    findMinElevationIndexBetween(indexA, indexB) {
        let start = indexA < indexB ? indexA : indexB;
        let finish = indexA < indexB ? indexB : indexA;
        let minElevation = this._elevations[start];
        let minElevationIndex = start;
        for (let i = start; i <= finish; i++) {
            if (this._elevations[i].elevation <= minElevation.elevation) {
                minElevationIndex = i;
                minElevation = this._elevations[i];
            }
        }
        return minElevationIndex;
    }

    findMaxElevationIndexBetween(indexA, indexB) {
        let start = indexA < indexB ? indexA : indexB;
        let finish = indexA < indexB ? indexB : indexA;
        let maxElevation = this._elevations[start];
        let maxElevationIndex = start;
        for (let i = start; i <= finish; i++) {
            if (this._elevations[i].elevation >= maxElevation.elevation) {
                maxElevationIndex = i;
                maxElevation = this._elevations[i];
            }
        }
        return maxElevationIndex;
    }

    /**
     * A separation zone is ignored if:
     *  - The maximum height is invalid. Happens if the terrain is flat or a constant slope.
     *      A maximum height is considered to be invalid if the difference between the maximum and
     *      minimum height is less than 10 meters, or the peak is somehow closer to the building
     *      than the first height sampled.
     *      The min height is set to the max height when a new max height is found upwind of the previous
     *      max height, as a result the difference between the min and max tends to be less than 10 meters
     *      when the building is at the bottom of a constant slope.
     *  - The zone is more than 80% of the sample distance.
     *  - The site is somehow above the max height.
     */
    ignoreSeparationZone(x, h, lu, lTwo, maxElevation) {
        return h < 10 ||
            lTwo / this._distance > 0.80 ||
            lu < 25 || // ???????????????????????????????? lu < 25m invalid? ??????????????????????????????
            this._siteElevation.elevation > maxElevation;
    }

    setWorstCase(isWorstCase) {
        if (isWorstCase != this._isWorstCase) {
            this._isWorstCase = isWorstCase;
            this.clearData();
        }
    }

    isWorseThan(otherTerrainProfile) {
        if (this._isSiteWithinZone && !otherTerrainProfile._isSiteWithinZone) {
            return true;
        } else if (!this.zoneIsInvalid() && otherTerrainProfile.zoneIsInvalid()) {
            return true;
        } else return this._mh > otherTerrainProfile._mh;
    }

    zoneIsInvalid() {
        return this.ignoreSeparationZone(this._x, this._h, this._lu, this._lTwo, this._maxElevation.elevation);
    }

    clearData() {
        this._chartData = null;
        this._tableData = null;
    }

    getTableData() {
        if (!this._tableData) this.buildData();
        return this._tableData;
    }

    getChartData() {
        if (!this._chartData) this.buildData();
        return this._chartData;
    }

    buildData() {
        if (this._chartData || !this._maxElevation) return;

        /** @param data.addColumn */
        /** @param data.addRow */
        /** @param data.setCell */
        const data = new this.google.visualization.DataTable();
        data.addColumn('number', 'Distance from Max-Height');
        data.addColumn('number', 'Elevation');
        data.addColumn({type: 'string', role: 'annotation'});
        data.addColumn({type: 'string', role: 'annotationText'});
        data.addColumn({type: 'number', role: 'interval'});
        data.addColumn({type: 'number', role: 'interval'});
        data.addColumn({type: 'boolean', role: 'scope'});
        data.addColumn({type: 'boolean', role: 'emphasis'});

        for (let i = 0; i < this._elevations.length; i++) {
            const inZone = !(i < this._zoneEdgeIndex && i < this._maxElevationIndex) && !(i > this._zoneEdgeIndex && i > this._maxElevationIndex);
            const inRange = this._maxElevationIndex - (this._minElevationIndex - this._maxElevationIndex) <= i && i <= this._minElevationIndex;
            let distanceFromPeak = this.google.maps.geometry.spherical.computeDistanceBetween(this._maxElevation.location, this._elevations[i].location);
            if (i < this._maxElevationIndex) {
                distanceFromPeak = -distanceFromPeak;
            }
            const elevation = {
                v: this._elevations[i].elevation,
                f: this._elevations[i].elevation.toFixed(3) + 'm',
            };
            data.addRow([{v: distanceFromPeak, f: distanceFromPeak.toFixed(3) + 'm'}, elevation, null, null, null, null, inRange, inZone]);
        }

        data.setCell(this._halfElevationIndex, 4, this._minElevation.elevation);
        data.setCell(this._halfElevationIndex, 5, this._maxElevation.elevation);
        data.setCell(this._minElevationIndex, 4, this._minElevation.elevation);
        data.setCell(this._minElevationIndex, 5, this._maxElevation.elevation);
        data.setCell(this._maxElevationIndex, 4, this._minElevation.elevation);
        data.setCell(this._maxElevationIndex, 5, this._maxElevation.elevation);
        data.setCell(this._originIndex, 2, 'Site');
        if (this.topoMap._addressMarker.address) {
            data.setCell(this._originIndex, 3, this.topoMap._addressMarker.awayFromAddress);
        }
        this._chartData = data;
        const colour = this.zoneIsInvalid() ? '#ffffdd' : this._isSiteWithinZone ? '#ffdddd' : '#ddffdd';
        const style = {style: 'background: ' + colour + ';' + (this._isWorstCase ? 'font-weight: bolder;' : '')};
        this._tableData = [
            {v: this.constructor.getHeadingSortValue(this._heading), f: this._direction.fname},
            {v: this._heading, f: this._heading.toFixed(3) + '&deg;'},
            {v: this._h, f: this._h.toFixed(3) + 'm', p: style},
            {v: this._x, f: this._x.toFixed(3) + 'm', p: style},
            {v: this._lu, f: this._lu.toFixed(3) + 'm', p: style},
            {v: this._mh, f: this._mh.toFixed(3), p: style},
            {v: this._slope, f: this._slope.toFixed(3), p: style},
            {v: this._maxElevation.elevation, f: this._maxElevation.elevation.toFixed(3) + 'm'},
            {v: this._minElevation.elevation, f: this._minElevation.elevation.toFixed(3) + 'm'},
            {v: this._halfElevation.elevation, f: this._halfElevation.elevation.toFixed(3) + 'm'},
            {v: (360 - this.constructor.getHeadingSortValue(this._heading)) * (this._isWorstCase ? -1 : 1), f: this._isWorstCase ? '✔' : '✗'},
        ];
    }

    static getHeadingSortValue(heading) {
        // clockwise from north
        heading += 15; // -15 to 15 is considered north, -16 would be northwest
        return (heading < 0 ? Math.abs(heading + 360) : heading) % 360;
    }

    addMarker(marker) {
        this._markers.push(marker);
        if (this._onMarkerClick) {
            this.google.maps.event.addListener(marker, 'click', this._onMarkerClick);
        }
    }

    onMarkerClick(cb) {
        this._onMarkerClick = cb;
        for (const marker of this._markers) {
            this.google.maps.event.addListener(marker, 'click', this._onMarkerClick);
        }
    }

    createMapMarkers() {
        this.clearMarkers();
        const colour = this.zoneIsInvalid() ? '#ffff00' : this._isSiteWithinZone ? '#ff0000' : '#00ff00';
        this.addMarker(this.topoMap.createMarker(
            this._maxElevation.location, this._direction.name + ' peak (' + this._maxElevation.elevation.toFixed(2) + ')',
        ));
        this.addMarker(this.topoMap.drawLine(this._maxElevation.location, this._zoneEdge, colour, this._direction.name));
    }

    clearMarkers() {
        this.hideMarkers();
        this._markers = [];
    }

    showMarkers() {
        /** @param marker.setMap */
        for (const marker of this._markers) {
            marker.setMap(this.topoMap._map);
        }
    }

    hideMarkers() {
        for (const marker of this._markers) {
            marker.setMap(null);
        }
    }
}